import TypeAnimation from "react-type-animation";
import "./css/Core.css";

function App() {
  return (
    <div className="Core">
      <img alt="Logo" src="/logo.png" width="256px" />
      <TypeAnimation
        className="Core-Typewrite"
        cursor={false}
        sequence={["We'll be ready soon"]}
        wrapper="h1"
      />
      <h2 className="Core-Subheader">
        At the moment, Solara is remaking its frontend website. Our team is working incredibly hard to prepare
        the website for the public!
      </h2>
    </div>
  );
}

export default App;
